.dashboard-nav{
    display : flex;
    justify-content: space-between;
    margin-bottom: 10px;
    flex-flow: wrap-reverse;
    align-items: center;
}

@media screen and (max-width: 1200px) {
    .dashboard-nav{
        flex-flow : wrap;
    }
}
.dashboard-btn{
    width: 134px;
    height: 40px;
}
.mini-btn{
    width: 50px;
    height: 40px;
    padding: 0 !important;
}

.from-date-div{
    margin-right: 5px;
}
.date-label{
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
    padding-left: 5px;
}

.device-dropdown{
    width: 380px;
}

.dashboard-datepicker > .DayPickerInput > input{
    width: 188px !important;
    min-width: 188px !important;
}

.date-group{
    display: flex;
}

.nav-group{
    display: flex;
    flex-direction: column;
}

.DayPickerInput > input {
    width: 150px;
    cursor: pointer;
    word-wrap: break-word;
    white-space: normal;
    outline: 0;
    -webkit-transform: rotateZ(0);
    -ms-transform: rotate(0);
    transform: rotateZ(0);
    min-width: 14em;
    min-height: 2.71428571em;
    background: #fff;
    display: inline-block;
    padding: .67857143em 2.1em .67857143em 1em;
    color: rgba(0,0,0,.87);
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    -webkit-transition: width .1s ease,-webkit-box-shadow .1s ease;
    transition: width .1s ease,-webkit-box-shadow .1s ease;
    -o-transition: box-shadow .1s ease,width .1s ease;
    transition: box-shadow .1s ease,width .1s ease;
    transition: box-shadow .1s ease,width .1s ease,-webkit-box-shadow .1s ease;
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

.refresh-container{
    display: flex;
    flex-direction: row-reverse;
    color: #a0a0a0;
}
