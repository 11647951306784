.split-pane-card {
    /*width: 1050px !important;*/
    max-width: 80vw !important;
}

.split-container {
    width: 100%;
    display: flex;
}

.wider-desc {
    width: 400px;
    padding-right: 30px;
}

.wide-desc {
    width: 450px;
    padding-right: 30px;
    font-size: 16px;
}

.even-split {
    justify-content: space-between;
}

.space-around {
    justify-content: space-around;
}

.items {
    flex: 1 1;
}

.wide-no-flex-items {
    width: 495px;
}

.flex-block-item {
    padding-bottom: 7px;
    border-bottom: 1px solid rgba(192, 192, 192, 0);
    -webkit-transition: border 500ms ease;
    -moz-transition: border 500ms ease;
    -ms-transition: border 500ms ease;
    -o-transition: border 500ms ease;
    transition: border 500ms ease;
    margin-top: 3px;
    margin-bottom: 7.5px;
}

.flex-block-item-span-container {
    padding-bottom: 7px;
    -webkit-transition: border 500ms ease;
    -moz-transition: border 500ms ease;
    -ms-transition: border 500ms ease;
    -o-transition: border 500ms ease;
    transition: border 500ms ease;
    margin-top: 3px;
    margin-bottom: 7.5px;
    cursor: pointer;
}

.flex-block-item:hover {
    border-bottom: 1px solid rgba(192, 192, 192, 1);
}

.surround-padding {
    padding: 5px;
}

.flex-block-item-right-justify {
    float: right;
    clear: right;
}

.form-control {
    display: block;
    width: 100%;
    padding: .5rem .75rem;
    font-size: 1rem;
    line-height: 1.25;
    color: #495057;
    background-color: #fff;
    background-image: none;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.forty-height {
    height: 40px;
}

.forty-five-height {
    height: 45px;
}

.fifty-height {
    height: 50px;
}

.form-group {
    position: relative !important;
    margin-bottom: 2rem !important;
}

.reduced-width > input {
    width: 70%;
}

.form-control-placeholder {
    position: absolute !important;
    top: 0 !important;
    padding: 7px 0 0 13px !important;
    transition: all 200ms !important;
    opacity: 0.5 !important;
    color: rgb(0, 159, 194);
}

.form-control:focus + .form-control-placeholder,
.form-control:disabled + .form-control-placeholder,
.form-control:valid + .form-control-placeholder {
    font-size: 90%;
    transform: translate3d(0, -100%, 0);
    opacity: 1;
    color: rgb(0, 159, 194);
}

.form-control:-webkit-autofill + .form-control-placeholder {
    font-size: 90%;
    transform: translate3d(0, -100%, 0);
    opacity: 1;
    color: rgb(0, 159, 194);
}

.float-label-invalid {
    color: #d80000 !important;
}

.float-input-invalid {
    border: 1px solid #d80000 !important;
}