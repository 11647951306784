.table-container {
    display: flex;
    flex-direction: column;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.table-header-flex {
    margin-top: 8px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
}

.table-header {
    margin-top: 8px;
    margin-bottom: 10px;
}

.-next {
    display: flex;
    justify-content: flex-end;
}

.ReactTable .-pagination .-btn {
    width: 50% !important;
}

.pagination-top {
    margin-bottom: 15px;
}

.-pagination {
    border-bottom: 1px solid #b1adad;
    margin-left: 5px;
    margin-right: 5px;

    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border-top: none !important;
}

.rt-thead {
    font-weight: bold;
}

.ui.table tbody tr:hover {
    background: rgba(0, 0, 0, .05);
    color: rgba(0, 0, 0, .95);
}

.light-grey-tr {
    background-color: #eaeaea;
}

.light-yellow-tr {
    background-color: #f7f5c1;
}

.light-yellow-tr:hover {
    background-color: #fffdca !important;
}

.red-tr {
    background-color: rgba(255, 0, 30, 0.642);
}

.light-red-tr {
    background-color: rgba(255, 33, 60, 0.33);
}

.light-red-tr:hover {
    background-color: rgba(255, 33, 60, 0.19) !important;
}

.light-green-tr {
    background-color: #a2f28f !important;
}

.light-green-tr:hover {
    background-color: #aeff9b !important;
}

.inner-table-container {
    padding: 30px;
}

.expandable-column {
    width: 30px;
}

.actions-column {
    width: 60px;
}

/*this css might be a terrible idea, we need to keep an eye on how table cells react*/
/* https://stackoverflow.com/questions/48853012/how-to-do-word-wrap-for-data-using-react-table */
/*.ReactTable .rt-td{*/
/*white-space: unset !important;*/
/*}*/

.cycles-by-day-header {
    font-size: 16px;
    /*background-color: rgba(255, 130, 87, 0.48);*/
    /*background-color: rgba(160, 230, 255, 0.48);*/
    /*background-color: #00b5ad1f;*/
    /*background-color: #ffa2007a;*/
}

.x-scroll {
    overflow-x: scroll;
}

.pagination-height {
    height: 43px;
}

.pagination-dropdown {
    width: 115px;
    margin-right: 15px;
}

.table-header-label-padding {
    margin-bottom: 2px;
    margin-left: 5px;
}

.table-sub-component-container {
    width: 0;
}

.table-sort-desc {
    border-bottom: 3px solid darkorange !important;
}

.table-sort-asc {
    border-top: 3px solid #009FC2 !important;
}

/*TODO: resizing stuff*/
/*.resizer {*/
/*display: inline-block;*/
/*background: blue;*/
/*width: 10px;*/
/*height: 100%;*/
/*position: absolute;*/
/*right: 0;*/
/*top: 0;*/
/*transform: translateX(50%);*/
/*z-index: 1;*/

/*}*/

/*.resizer.isResizing {*/
/*background: red;*/
/*}*/