.create-a-machine-card{
    width: 1050px !important;
    max-width: 80vw !important;
}

/*.create-a-machine-form{*/
    /*display: flex;*/
/*}*/

/*.create-desc {*/
    /*width: 400px;*/
/*}*/

/*.create-config-items{*/
    /*flex: 1 1;*/
/*}*/

.red-text{
    color: #c80000;
}

/*.create-a-machine-form{*/
    /*width: 500px;*/
/*}*/

.software-affecting{
    width: 100%;
    display: flex;
    border-bottom: 3px solid black;
    margin-bottom: 16px;
}

.non-software-affecting {
    width: 100%;
    display: flex;
}

.desc{
    width: 300px;
    padding-right: 30px;
}

.items{
    flex: 1 1;
}


.create-a-machine-dropdown{
    /*float: right;*/
    /*clear: right;*/
}

.submit-machine{
    float: right;
    clear: right;
    cursor: pointer;
    background-color: rgb(0, 159, 194) !important;
}

.submit-machine:disabled{
    cursor: not-allowed !important;
}

.mint{
    color: #72CB67;
    font-size: 40px;
}

.success-div{
    font-size: 20px;
}

.hyperlink-div{
    margin-top: 60px;
}

.top-success-div{
    margin-bottom: 60px;
}

.right-input {
    min-width: 150px;
    max-width: 150px;
}

.display-inline{
    display: inline-block;
}

.success-text{
    font-size: 1.25em;
    margin-bottom: 10px;
}