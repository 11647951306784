.signup-form{
    background-color: #fff;
    border-radius: 6px;
    margin: 35px auto;
    width: 500px;
    height: 600px;
}
.signup-form.long{
    height: 630px;
}
.signup-form.mini{
    height:200px;
}
.signup-intro{
    text-align: center;
    font-size: 16px;
    color: #aaa;
    margin-bottom: 18px;
}
.disabled-input{
    cursor: not-allowed;
}

.signup-p{
    text-align: center;
    font-size: 14px;
    color: #aaa;
    margin-bottom:5px;
}

.signup-textbody{
    margin-bottom: 40px;
}
.signup-body{

}