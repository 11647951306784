.green{
    color: green;
}
.light-green{
    color: #20bc4c;
}
.red{
    color: #db2828;
}

.green-btn{
    background-color: #20bc4c !important;
    border-color: #20bc4c !important;
    color: #fff !important;
}

.red-btn{
    background-color: #db2828 !important;
    border-color: #db2828!important;
    color: #fff !important;
}

.thin-btn{
    height: 38px;
    width: 150px;
}

.admin-check-div{
    display: flex;
    justify-content: space-around;
}

.overflow-visible-td{
    overflow: visible !important;
    display: flex;
    justify-content: space-around;
}

.rt-table{
    overflow: visible !important;
}

.rt-tbody{
    overflow: visible !important;
}

.ReactTable .rt-thead .rt-th.-sort-desc, .ReactTable .rt-thead .rt-td.-sort-desc{
    /*box-shadow: inset 0 -3px 0 0 rgb(0, 159, 194);*/
    box-shadow: inset 0 -3px 0 0 rgba(0, 159, 194, 0.6)
}

.ReactTable .rt-thead .rt-th.-sort-asc, .ReactTable .rt-thead .rt-td.-sort-asc{
    box-shadow: inset 0 3px 0 0 rgba(255, 118, 0, 0.6)
}

/*.ReactTable .-pagination .-btn{*/
    /*background: rgba(0, 159, 194, 0.1) !important;*/
/*}*/


.operator-badge{
    border-radius: 2px;
    border: 1px solid #ddd;
    background-color: #fff;
    color: #009FC2;
    text-transform: uppercase;
    margin-right: 2px;
    margin-bottom: 2px;
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
}

.contact-info-container{
    display: flex;
    flex-direction: column;
}

.contact-info-container-inner{
    display: flex;
}

.new-line {
    white-space: pre-line;
  }