.wave-wrapper{
  width:100%;
  display:flex;
  min-height:100vh;
  align-items: center;
}
.wave {
  display: inline-block;
  position: absolute;
  width: 400px;
  height: 400px;
  left:50%;
  top:50%;
  transform: translate(-50%, -50%)
}
.wave:before, .wave:after {
  position: absolute;
  content: '';
  border: 4px solid #009FC2;
  opacity: 1;
  border-radius: 50%;
  left:50%;
  top:50%;
  transform: translate(-50%, - 50%);
  animation: wave 1.2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.wave:after {
  animation-delay: -0.4s;
}
@keyframes wave {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
