.customer-content {
    padding-top: 15px;
}

.customer-machine-block {
    border: 2px solid #AAA !important;
    display: inline-block;
    vertical-align: top;
    margin: 1%;
    border-radius: 6px;
    position: relative;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -o-transition: all .2s linear;
    transition: all .2s linear;
    flex: 0 0 23%;
    height: 354px;
}

.customer-title-block{
    flex: 0 0 25%;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    border-width: 30px;
    border-color: red;
}

@media screen and (min-width: 966px) and (max-width: 1200px) {
    .customer-machine-block {
        flex: 0 0 31%;
    }
}

@media screen and (min-width: 650px) and (max-width: 965px) {
    .customer-machine-block {
        flex: 0 0 31%;
    }
}

.sec {
    font-size: 13px;
    color: #555;
    display: flex;
    justify-content: space-between;
    min-width: 0; /* NEW */
    min-height: 50px;
}

.sec p {
    /* BOTH of the following are required for text-overflow */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.text-overflow-max-width {
    max-width: 100px;
}

.machine-icon {
    text-align: center;
    padding: 30px 8px;
    border-bottom: 1px solid #eee;
    height: 220px;
}

.util-section {
    font-size: 13px;
    color: #555;
    display: flex;
    justify-content: space-between;
}

.machine-icon-big {
    text-align: center;
    padding: 30px 8px;
    height: 286px;
}

.dropdown {
    position: absolute;
    right: 0;
    display: flex;
    top: 0;
    border-top: none;
    border-right: none;
    -webkit-transition: all .2s linear;
    -moz-transition: all .2s linear;
    -o-transition: all .2s linear;
}

.name {
    font-weight: 700;
    color: #333;
    font-size: 15px;
    margin-bottom: 4px;
    white-space: nowrap;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.customer-device-view-dashboard-div {

}

.customer-device-view-dashboard-btn {
    height: 100%;
    width: 100%;
    border-radius: 0 0 4px 4px !important;
    /*background-color: #2fcb52 !important;*/
}

.customer-device-title {
    display: flex;
    flex-direction: column;
}

.full-nav {
    display: block;
    padding: .78571429rem 1.14285714rem !important;
}

.remove-item-padding {
    padding: 0 !important;
}

.add-item-padding {
    padding: .78571429rem 1.14285714rem !important;
}

.fake-dropdown-item {
    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    border-top: none;
    line-height: 1em;
    color: rgba(0, 0, 0, .87);
    font-size: 1rem;
    text-transform: none;
    font-weight: 400;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-touch-callout: none;
}

.customer-device-header {
    height: 34px;
    border-radius: 4px 4px 0 0;
}

.customer-device-device-state__p{
    padding: 7px;
    font-weight: bolder;
}

.device-ready{
    position: absolute;
    color: green;
}

.device-error{
    position: absolute;
    color: red
}

.device-processing{
    position: absolute;
    color: yellow
}

.device-unknown{
    position: absolute;
    color: rgb(185, 181, 181)
}

.device-utilization{
    position: absolute !important;
    height: 20px !important;
    margin-top: 72px !important;
    background-color: #e3e3e3 !important;
    margin-left: 1px !important;
}