.bc-green-tr {
    background-color: #01AF00 !important;
}

.bc-green-tr:hover {
    background-color: #3ec73e !important;
}

.bc-yellow-tr {
    background-color: #f8e24c !important;
}

.bc-yellow-tr:hover {
    background-color: #f8e984 !important;
}

.bc-dark-yellow-tr {
    background-color: #e18a19 !important;
}

.bc-dark-yellow-tr:hover {
    background-color: #fcbc69 !important;
}

.bc-red-tr {
    background-color: #f54646 !important;
}

.bc-red-tr:hover {
    background-color: #f86868 !important;
}

.bc-light-red-tr {
    background-color: #f14444 !important;
}

.bc-light-red-tr:hover {
    background-color: #ee6161 !important;
}