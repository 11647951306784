/*body, p, h1, h2, h3, h4, h5 {*/
    /*font-family: Open Sans, Arial, Helvetica, sans-serif;*/
    /*font-size: 15px;*/
    /*font-weight: 400;*/
/*}*/
.customer-container {
    max-width: none !important;
    width: 1200px !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
    margin-right: auto !important;
    margin-left: auto !important;
    margin-top: 8px !important;
}

.customer-footer{
    max-width: none !important;
    width: 1200px !important;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
}

@media screen and (min-width: 966px) and (max-width: 1200px) {
    .customer-container {
        max-width: none !important;
        width: 900px !important;
        margin-top: 15px !important;
        margin-right: auto !important;
        margin-bottom: 0px !important;
        margin-left: auto !important;
    }

    .customer-footer{
        max-width: none !important;
        width: 900px !important;
        margin: 0 auto !important;
    }

}


@media screen and (min-width: 650px) and (max-width: 965px) {
    .customer-container {
        max-width: none !important;
        width: 780px !important;
        margin-top: 15px !important;
        margin-right: auto !important;
        margin-bottom: 0px !important;
        margin-left: auto !important;
    }

    .customer-footer{
        max-width: none !important;
        width: 780px !important;
        margin: 0 auto !important;
    }

}

.customer-tabs > .ui.attached.tabular.menu  > .item.active {
    font-family: Open Sans, Arial, Helvetica, sans-serif;
    background-color: #fff;
    color: #009FC2 !important;
    cursor: default;
    font-size: 22px;
    color: rgba(255,255,255,.8);
    padding: 12px 25px;
    font-weight: 300 !important;
    border-radius: 5px 5px 0 0 !important;
}

.customer-tabs > .ui.attached.tabular.menu > .item:not(.active) {
    font-family: Open Sans, Arial, Helvetica, sans-serif;
    font-size: 22px;text-align: center;
    background-color: rgba(0,0,0,.4);
    color: rgba(255,255,255,.8);
    border: none;
    padding: 12px 25px;
    font-weight: 300 !important;
    border-radius: 5px 5px 0 0 !important;
}

.customer-header{
    display: flex;
    border-bottom: 1px solid rgba(0,0,0,.1);
    height: 75px;
}
.stat-info{
    flex: 1 1;
    display: flex;
}

.right-actions{
    /*height: 40px;*/
    display: flex;
    align-items: center;
    justify-content: center;
}



.item-side-padding{
    margin-left: 5px;
    margin-right: 5px;
}
.stat-name{
    font-weight: 700;
    color: #AAA;
    font-size: 13px;
    /*display: inline-block;*/
    /*vertical-align: top;*/
    margin-top: auto;
    margin-bottom: 7px;
}
.large-num{
    color: #009FC1;
    font-size: 40px;
    margin-bottom: 0 !important;
    margin-top: auto !important;
    margin-right: 11px !important;
}

.filter-by{
    color: #aaa;
    text-align: right;
    font-size: 13px;
    padding-top: 6px;
    margin: 0;
    margin-right: 5px;
}

.machines-for-a-customer-ster{
    /*border: 1px solid black;*/
    margin-bottom: 10px;
    /*border-left: 5px solid #e1dada;*/
    border-left: 5px solid rgba(0, 159, 194, 0.45);
    margin-bottom: 10px;
    padding-left: 8px;
    display: flex;
    flex-flow: column;
}

.machines-for-a-facility-ster{
    display: flex;
    flex-flow: row wrap;
    /*flex-wrap: wrap;*/
    /*border-left: 3px solid #e1dada;*/
    border-left: 3px solid #ff5a004d;
    /*margin: 15px;*/
    margin-left: 15px;
    padding-left: 8px;
    margin-bottom: 15px;
}


.float-right-grey{
    float: right;
    color: #aaa;
    position: absolute;
    right: 0;
    margin-right: 10px;
}
.customer-device-title{
    height: 60px;
    padding: 12px 10px;
}

/*.checkbox{*/
    /*position: absolute;*/
    /*left: 6px;*/
    /*top: 6px;*/
/*}*/

.facility-name-div{
    width: 100%;
    margin-bottom: 7px;
    margin-top: 7px;
}

h2{
    color: rgb(0, 159, 194);
}
h3{
    color: rgb(0, 159, 194);
}
h4{
    color: rgb(0, 159, 194);
}

/*.ui.bottom.attached.segment.active.tab {*/
    /*height: 73vh !important;*/

/*}*/

.footer-hyperlink-div{
    display: flex;
    padding-bottom: 10px;
}


.customer-tab-div {
    overflow: visible !important;
}

.height-restricted-content{
    height: 70vh !important;
    overflow-y: auto;
}
.customer-table{
    width:99%; /* Need enough width to compensate for scroll bar */
}

@media screen and (min-height: 800px) and (max-height: 1080px) {
     .height-restricted-content{
         height: 62vh !important;
         overflow-y: auto;
    }
}

@media screen and (max-height: 800px) {
    .height-restricted-content{
        height: 52vh !important;
        overflow-y: auto;
    }
}


.left-hyperlink{
    padding-right: 10px;
    border-right: 1px solid #F0EBE8;
}


.right-hyperlink{
    margin-left: 10px;
}

.hyperlink{
    font-size: 12px;
    font-weight: 700;
    color: #666;
    text-decoration: none;
}

.footer-p{
    font-size: 13px;
    color: #60606099;
}

.top-div{
    display: flex;
    min-height: 20px;
}
.filter-by-div{
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.selected-machine{
    border-color: #009FC2;
}

.ellipsis-container{
    font-size: 18px;
    padding-bottom: 10px;
    padding-left: 10px;
    line-height: 1em;
    border-radius: 0;
    border-bottom-left-radius: 0px;
    border-bottom-left-radius: 4px;
    background-color: #aaa;
    color: #fff;
    padding-right: 7px;
    padding-top: 5px;
}

/*.device-checkbox{*/
    /*height: 50px;*/
    /*min-height: 50px;*/
    /*max-height: 50px;*/

    /*width: 50px;*/
    /*min-width: 50px;*/
    /*max-width: 50px;*/
/*}*/

.actions-btn:hover{
    background-color: #777 !important;
    border-color: #777 !important;
    color: #fff !important;
}

.ster-btn{
    background-color: #009FC2 !important;
    border-color: #009FC2 !important;
    color: #fff !important;
}
.actions-btn{
    background-color: #009FC2 !important;
    border-color: #009FC2 !important;
    color: #fff !important;
    margin-top: auto !important;
    margin-bottom: 8px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 105px;
    border-radius: 6px;
    font-size: 13px;
    font-weight: bolder;
    transition: all .2s linear !important;
}

.ui.active.visible.top.right.pointing.dropdown.actions-btn.item-side-padding{
    background-color: #777 !important;
    border-color: #777 !important;
    color: #fff !important;
}


.grey-btn{
    background-color: #777 !important;
    border-color: #777 !important;
    color: #fff !important;
}

.run-reports{
    color: #eee;
    font-weight: 700;
    font-size: 15px;
}

.run-reports:hover{
    color: #fff;
    background-color: rgba(0,0,0,.15);
}
.customer-tabs > .ui.attached.tabular.menu{
    /*
        this 50% is to allow the 'New User' button to float to the right of the tabs in the container div
        it takes up 100% by default, but we only have a limited number of tabs so 50% is fine

    */
    width: 50% !important;
    height: 60px;
}

.new-device-operator-div{
    float: right;
}

.ster-btn-wide{
    height: 45px;
    width: 180px;
    padding: 0 !important;
}

.new-user-icon{
    padding-left: 13px;
}

.ui.dimmer{
    background-color: rgba(0, 0, 0, 0.34);
}


.full-width{
    width: 100%;
}

.fake-float{
    color: rgb(0, 159, 194);
    padding: 7px 0 0 13px !important;
    font-size: 90%;
    position: absolute !important;
    top: -26px !important;
    opacity: 0.5 !important;
}
.create-device-operator-ster{
    height: 600px
}
@media only screen and (max-height: 1000px) {
    .create-device-operator-ster{
        height: 500px;
    }
}

.create-site-admin-ster{
    height: 675px
}
@media only screen and (max-height: 1000px) {
    .create-site-admin-ster{
        height: 675px;
    }
}



.create-a-device-operator-form{
    min-height: 418px;
}

.edit-a-site-admin-form{
    min-height: 240px;
}

.create-a-site-admin-form{
    min-height: 490px;
}

.ui.dimmer {
    transition: background-color 0.5s ease;
}

.light-validation-error{
    color: rgba(255, 5, 2, 0.51);
}

.shake {
  animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

.ui.page.modals.transition.visible {
    display: flex !important;
}

.cycle-report-container{
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.customer-cycle-report-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.customer-cycle-report-container input{
    width: 100% !important;
}

.customer-cycle-report-container .DayPickerInput-OverlayWrapper{
    width: 100% !important;
}

.cycle-report-modal-content{
    overflow-y: visible !important;
}

.psi-dropdown{
    height: 40px;
    width: 50px;
    max-width: 50px;
    min-width: 90px;
}

.big-notif{
    height: 100px;
    width: 400px;
    font-size: 2em;
    margin: 5px;
    text-align: center;
    line-height: 1.1;
}

.filter-by-customer-dropdown{
    min-width: 225px !important;
}

.medium-width{
    width: 295px;
}

.customer-cycle-report-btn{
    margin-bottom: 7px !important;
    margin-top: auto !important;
    height: 50px;
}