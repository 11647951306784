.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.ster-img {
    width: 213px;

}

.nav-img {
    width: 199px;
    margin-top: 3px;
}

.ster-solutions-logo {
    width: 333px;
}

.nav-container {
    color: rgba(0, 0, 0, 0.87);
    background-color: #009FC2;
    display: flex;
    justify-content: space-around;
}

.top-nav {
    height: 58px;
    max-width: 1440px;
    /*color: rgba(0, 0, 0, 0.87);*/
    /*background-color: #009FC2;*/
    width: 100%;
    display: flex;
    justify-content: space-between;

}

.logo-left {
    margin-left: 15px;
}

.side-menu .menu-item:nth-child(even) {
    background-color: #f8f8f8 !important;
}

.side-menu .menu-item:nth-child(even):hover {
    background-color: #f1f1f1 !important;
}

.side-menu .menu-item:nth-child(odd) {
    background-color: #d8d8d8 !important;
}

.side-menu .menu-item:nth-child(odd):hover {
    background-color: #c0c0c0 !important;
}

.collapsed-menu-item {
    margin: 13px;
    text-decoration: underline;
    cursor: pointer;
}

.collapsed-menu-item-no-margin {
    text-decoration: underline;
    cursor: pointer;
}

.side-menu .menu-item:nth-child(even) {
    background-color: #f8f8f8 !important;
}

.side-menu .menu-item:nth-child(even):hover {
    background-color: #f1f1f1 !important;
}

.side-menu .menu-item:nth-child(odd) {
    background-color: #d8d8d8 !important;
}

.side-menu .menu-item:nth-child(odd):hover {
    background-color: #c0c0c0 !important;
}

.side-menu {
    min-width: 180px;
    border-right-style: none !important;
}

.side-menu a:nth-child(even) .menu-item {
    background-color: #e7e7e7 !important;
}

.side-menu a:nth-child(even) .menu-item:hover {
    background-color: #c0c0c0 !important;
}

.menu-text {
    position: absolute;
    left: 0;
    margin: 5px;
    padding-left: 16px;
}

.menu-icon {
    position: absolute;
    right: 0;
    margin: 5px;
}

.menu-item:hover {
    background-color: #ececec !important;
}

.menu-item {
    min-height: 60px !important;
}

.collapseIcon {
    right: 0;
    position: absolute;
    z-index: 10000;
    top: 5px;
}

.side-menu-header {
    font-size: larger !important;
}

.side-bar {
    width: 250px !important;
    font-size: 20px !important;
}

.ui.segment.pushable {
    background-color: rgb(237, 236, 236) !important;
}

.card-body {
    min-height: 70vh;
}

html {
    background-color: #F9F7F6 !important;
}

* {
    box-sizing: border-box
}

body {
    height: 100%;
}

.app {
    height: 100%;
}

.ui.bottom.attached.segment.active.tab {
    /*min-height: 630px;*/
    /*height: 630px;*/
}

.ui.secondary.vertical.pointing.menu {
    margin: 0;
}

.ster-btn {
    background-color: #009FC2 !important;
    color: white !important;
}

.ster-btn:hover {
    background-color: #088caa !important;
    color: white !important;
}

.app-container {
    flex: 1;
    background-color: #F9F7F6;
    height: 100%;
}

/*@media screen and (min-width: 650px) and (max-width: 965px) {*/
/*.app-container {*/
/*overflow-x: scroll;*/
/*}*/
/*}*/

#root {
    /*display: flex;*/
    /*height: 100%;*/
}

body {
    background-color: #F9F7F6;
}

.homepage-card {
    border: 2px solid #009FC2 !important;
    height: 235px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    cursor: pointer;
}

.homepage-card h4 {
    font-size: 50px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.sidebar-navlink {
    color: black;
}

@keyframes lineInserted {
    from {
        height: 0;
    }
    to {
        height: 20px; /* your line height here */
    }
}

.collapsedItem {
    min-height: 230px;
    transition: all .3s ease-in-out;
    cursor: default !important;
}

.mini-collapsedItem {
    min-height: 125px;
    transition: all .3s ease-in-out;
    cursor: default !important;
}

.large-collapsedItem {
    min-height: 325px;
    transition: all .3s ease-in-out;
    cursor: default !important;
}

.long-collapse {
    min-height: 300px;
}

.longer-collapse {
    min-height: 330px;
}

.slight-min-height {
    min-height: 65px;
}

.collapsible-item {
    transition: width 600ms ease-out, height 600ms ease-out;
}

.collapse-hyphen {
    font-size: 45px;
    position: absolute;
    left: 0;
    top: -30px;
    cursor: pointer !important;
}

.sidebar-navlink.selected {
    color: #1e70bf !important;
}

.upload-document--modal {
    width: 615px !important;
}

.tall-textarea {
    height: 100px;
    width: 560px;
}

.download-icon {
    color: #2d75d1;
    cursor: pointer;
}

.delete-icon {
    color: #b30a0a;
    cursor: pointer;
}

.doc-header {
    display: flex;
}

.slight-padding-bottom {
    padding-bottom: 5px;
}

.medium-padding-bottom {
    padding-bottom: 25px;
}

.slight-padding-top {
    padding-top: 10px;
}

.slight-margin-right {
    margin-right: 10px;
}

.container-404 {
    font-size: 2em;
    display: flex;
    /*justify-content: space-around;*/
    margin-top: 10vh;
    flex-direction: column;
    text-align: center;
}

.container-404 > a {
    margin-top: 25px;
}

.left-margin {
    margin-left: 15px;
}

.text-right-align {
    text-align: right;
}

.slight-left-margin {
    margin-left: 5px;
}

.medium-right-margin {
    margin-right: 20px;
}

.slight-right-margin {
    margin-right: 5px;
}

.important-left-margin {
    margin-left: 14px !important;
}

.file-request-metainfo {
    display: flex;
    flex-direction: column;
}

.flex-dir-column {
    display: flex;
    flex-direction: column;
}

.flex-dir-row {
    display: flex;
    flex-direction: row;
}

.flex-dir-row-reverse {
    display: flex;
    flex-direction: row-reverse;
}

.important-flex-row {
    flex-direction: row !important;
}

.modal-date-select-container {
    display: flex;
    justify-content: space-evenly;
}

.file-request-metainfo > div {
    display: flex;
}

.file-request-metainfo > div > h5 {
    margin-right: 5px;
    width: 275px;
}

.create-a-user-form {
    margin-top: 15px;
}

.filter-dropdown {
    width: 230px;
}

.long-dropdown {
    max-height: 350px !important;
}

.device-filter-header {
    margin-bottom: 7px !important;
    margin-left: 7px;
}

.device-filter-dropdown {
    max-width: 365px;
    min-width: 175px !important;
}

.wide-dropdown {
    max-width: 500px;
    min-width: 175px !important;
}

.abs-pos {
    position: absolute;
    right: 0;
}

.table-legend {
    float: right;
    display: flex;
    flex-direction: column;
}

.medium-margin-bottom {
    margin-bottom: 25px;
}

.large-margin-bottom {
    margin-bottom: 50px;
}

.medium-height {
    height: 205px;
}

.important-no-margin-bottom {
    margin-bottom: 0 !important;
}

.table-legend-header {
    text-align: right;
    cursor: pointer;
    text-decoration: underline;
}

.download-div {
    display: none;
}

.pos-bottom-right {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 2147483647;
    margin-right: 10px;
}

.slight-padding {
    padding-top: 5px;
}

.slight-margin-bottom {
    margin-bottom: 15px;
}

.slight-margin-top {
    margin-top: 15px;
}

.important-slight-margin-top {
    margin-top: 7px !important;
}

.medium-margin-top {
    margin-top: 25px;
}

.important-medium-margin-top {
    margin-top: 25px !important;
}

.important-medium-margin-bottom {
    margin-bottom: 25px !important;
}

.desc-text-example {
    margin-left: 5px;
    margin-bottom: 5px;
}

.short-modal-text {
    display: block;
    margin-bottom: 25px;
    font-size: 1.2em;
}

.modal-text {
    font-size: 1.2em;
}

.center-text {
    text-align: center;
}

.center-items {
    align-items: center;
}

.left-text {
    text-align: left;
}

.min-width-card {
    min-width: 950px;
}

fieldset {
    border: 1px solid #c0c0c0;
    margin: 5px 2px;
    padding: 0.35em 0.625em 0.75em;
}

legend {
    font-size: 18px;
    margin-bottom: 4px;
    padding-left: 5px;
    padding-right: 5px;
    width: auto;
}

.fake-hyperlink-for-hover:hover {
    color: #4183c4;
}

.fake-hyperlink {
    color: #4183c4;
}

.git-version {
    float: right;
    margin-right: 5px;
}

.left-label {
    display: inline-block;
    width: 125px;
}

.large-left-label {
    display: inline-block;
    width: 240px;
}

.tiny-left-label {
    display: inline-block;
    width: 70px;
}

.full-width {
    width: 100%;
}

.padding-left {
    padding-left: 15px;
}

.padding-right {
    padding-right: 15px;
}

/*
    Use this class extremely sparingly. whenever negative margin could be a solution,
     there is probably something better
*/
.negative-bottom-margin {
    margin-bottom: -16px;
}

.view-firmware-header {
    height: 75px;
}

.close-modal-btn {
    margin: 1rem 1rem !important;
    position: absolute;
    bottom: 0;
}

.device-info-block {
    width: 590px;
}

.medium-max-width {
    width: 300px;
    max-width: 300px;
}

.minor-padding {
    padding: 8px;
}

.homepage-cycle-chart-date-dropdown {
    width: 140px;
}

.homepage-cycle-chart-cycle-bucket-dropdown {
    width: 180px;
}

.ui.dropdown .menu {
    z-index: 999999 !important;
}

.high-z-index {
    z-index: 999999 !important;
}

.Toastify__toast--default {
    color: black !important;
}

.Toastify__progress-bar--default {
    background: #009FC2 !important;
}

.grey-text {
    color: #747474;
}

.view-customers-table-legend-btn {
    width: 165px;
    margin-left: auto !important;
}

.cycle-report-warning__container {
    /*display: flex;*/
    padding-top: 25px;
}

.cycle-report-warning__header {
    display: flex;
    justify-content: space-around;
}

.retire-device-modal-select-facility__container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.homepage__container__top-nav {
    display: flex;
    justify-content: space-between;
}

.wide-300 {
    width: 300px;
    min-width: 300px;
}

/*
    there's some weird CSS in semantic UI's css that will make it so the last tab doesn't have certain styles
    like the top border and the margin bottom, hence the important!s here

*/
.full-height-vertical-tab {
    height: 670px;
    border: 1px solid #d4d4d5 !important;
    /*border-left: none !important;*/
    margin-bottom: 1rem !important;
}

.medium-font {
    font-size: 16px;
}

.orange-icon {
    color: #ffa400;
}

.green-icon {
    color: green;
}

.flex-col-end {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

body {
    background-color: #F9F7F6 !important;
}

.sterilis-text-underline {
    text-decoration: underline wavy #009FC2;
}

.scroll-dialogue-medium {
    max-height: 250px;
    overflow-y: scroll
}

.red-foreground-white-background {
    color: red;
    background-color: white;
}

.mini-padding-top {
    padding-top: 2px;
}

.rwd-line {
    display: block;
}

@media screen and (min-width: 1350px) {
    .rwd-line {
        display: inline;
    }
}

.validation-error-background {
    background-color: #ff00000d !important;
}

.fade-in-div-enter {
    opacity: 0.01;
}

.fade-in-div-enter-active {
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 500ms;
}

.fade-in-div-exit {
    opacity: 1;
}

.fade-in-div-exit-active {
    opacity: 0.01;
    transition: visibility 0s linear 0s, opacity 500ms;
}

.customer-dropdown {
    width: 100%
}

.customer-dropdown > .text {
    width: 100%;
}

.sidebar-icon {
    position: initial !important;
    text-underline: none;
}

.no-margin-top {
    margin-top: 0;
}

.view-customer-filter {
    width: 235px;
}

.view-customer-filter_container {
    width: 600px;
    margin-top: 20px;
}

.heavy-left-margin {
    margin-left: 18em;
}

.ster-blue {
    color: rgb(0, 159, 194)
}

.float-label-anchor {
    position: absolute !important;
    top: 0 !important;
    padding: 7px 0 0 13px !important;
    transition: all 200ms !important;
    opacity: 0.5 !important;
    color: rgb(0, 159, 194);
}

.float-label-float {
    font-size: 90%;
    transform: translate3d(0, -100%, 0);
    opacity: 1;
    color: rgb(0, 159, 194);
}

.flex-dir-col-rev {
    display: flex;
    flex-direction: column-reverse;
}

.service-summary-green-cell {
    background: #0dbc0d94;
}

.service-summary-yellow-cell {
    background: #f7f5c1;
}

.service-summary-red-cell {
    background: rgba(255, 33, 60, 0.53);
}

.cycle-stats-container {
    width: 460px;
}

.burnin-cycle-stats-container {
    width: 450px;
}

.stat-container {
    width: 225px;
}

.parser-graph-btn {
    width: 160px
}

.parser-graph-btn-row {
    display: flex;
    justify-content: space-between;
}

.parser-graph-btn-group {
    display: flex;
    justify-content: space-around;
    margin-bottom: 10px;
    /*width: 400px;*/
}

.parser-graph-container {
    flex: 1;
}

.options-container {
    width: 98%;
    max-width: 950px;
}

.three-across-grid-container {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 31% 30% 30%;
    row-gap: 30px;
    column-gap: 25px;
}

.four-across-grid-container {
    width: 100%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 120px 120px 120px 120px;
}

.five-across-grid-container {
    width: 100%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 125px 125px 125px 125px 125px;
}

@media screen and (max-width: 1300px) {
    .five-across-grid-container {
        grid-gap: 30px;
        grid-template-columns: 150px 150px 150px;
    }
}

@media screen and (max-width: 950px) {
    .five-across-grid-container {
        grid-gap: 30px;
        grid-template-columns: 150px 150px;
    }
}

@media screen and (max-width: 500px) {
    .five-across-grid-container {
        grid-template-columns: 150px;
    }
}

.parser-table-container {
    width: 80%;
    margin-top: 15px;
    margin-right: 15px;
}

.burnin-parser-table-container {
    width: 75%;
    margin-top: 15px;
    margin-right: 15px;
}

.flex-self-center {
    display: flex !important;
    align-self: center;
    margin-left: auto !important;
    margin-right: auto !important;
}

.digital-output-toggle {
    padding: 5px;
}

.download-file-on-parser-container {
    width: 150px;
}

.no-margin-right {
    margin-right: 0 !important;
}

.batch-container {
    /*width: 400px;*/
}

.available-ethel-files-container {
    width: 100%
}

.batch-parser-date-picker-container {
    width: 450px;
}

.batch-parser-device-picker {
    width: 450px !important;;
}

.available-ethel-files-table-header {
    height: 60px;
}

.abnormal-value-popup {
    height: 675px !important;
}

.green-text {
    color: rgba(21, 104, 19, 0.58);
}

.dynamic-cycle-stat-input {
    height: 30px;
    width: 125px;
}

.burnin-stat-container {
    flex: 1;
    margin-left: 50px;
}

.burnin-left-container {
    width: 65%;
}

.show-chart-options-text {
    text-decoration: underline;
    cursor: pointer;
}

.hidden-chart-options-container {
    width: 220px;
}

.chart-options-container {
    max-width: 900px;
    height: 50px;
}

.burnin-chart-options-container {
    max-width: 73%;
}

.sticky-container {
    position: relative;
}

.burnin-stats-container {
    width: 450px;
    min-width: 250px;
    max-width: 450px;
}

.black-text {
    color: black;
}

.burnin-abnormal-values-container {
    width: 240px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.burnin-chart-stat-container {

}

.strong {
    font-weight: bold;
}

.fail-value-container {
    width: 200px;
}

.remaining-cycle-stat-summary {
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 15px;
    cursor: pointer;
}

.previous-burnin-tests {
    padding-left: 10px;
    border-left: 5px solid #ff5a004d;
    margin-left: 16px;
    margin-bottom: 50px;
    margin-top: 15px;
}

.detailed-cycle-stats {
    padding-left: 5px;
    border-left: 5px solid rgba(0, 159, 194, 0.45);
    margin-left: 22px;
}

.export-burnin-results-btn {
    width: 170px;
    height: 50px;
}

.important-margin-top-bottom {
    margin: 10px 0 10px 0 !important;
}

.side-drawer {
    overflow-y: scroll;
    height: 100%;
    background: white;
    position: fixed;
    top: 0;
    right: 0;
    width: 30%;
    z-index: 200;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
}

@media screen and (max-width: 1080px) {
    .side-drawer {
        width: 50%;
    }
}

@media screen and (min-width: 1080px) and (max-width: 1300px) {
    .side-drawer {
        width: 40%;
    }
}

@media screen and (min-width: 1301px) and (max-width: 1600px) {
    .side-drawer {
        width: 35%;
    }
}

.side-drawer.open {
    transform: translateX(0);
}

.drawer-chart-options-container {
    margin-left: 15px;
    margin-right: 15px;
}

.drawer-chart-options-digital-output-container {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 15px;

}

.drawer-chart-options-digital-output-container fieldset {
    padding-left: 30px;
    padding-right: 30px;
}

.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    top: 0;
    right: 0;
}

.drawer-header {
    padding: 1.25rem 1.5rem;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-bottom: 1px solid rgba(34, 36, 38, .15)
}

.flip-and-stick-right {
    position: fixed !important;
    right: 0;
    -webkit-transform-origin: 100% 50%;
    -moz-transform-origin: 100% 50%;
    -ms-transform-origin: 100% 50%;
    -o-transform-origin: 100% 50%;
    transform-origin: 80% 50%;
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
}

.no-burnin-test-found-container {
    margin-top: 20px;
    margin-bottom: 20px;

}

.detailed-cycle-stat-main-stat {
    cursor: pointer;
}

.inline-block-fbi {
    display: inline-block;
    width: 94%;
}

.modal-actions-container {
    height: 65px;
}

.scroll-top-icon {
    position: fixed;
    width: 100%;
    align-items: center;
    height: 20px;
    justify-content: center;
    z-index: 1000;
    cursor: pointer;
    animation: fade-in-scroll 0.3s;
    transition: opacity 0.4s;
    opacity: 0.5 !important;
    left: 7px;
    /* 50px to clear the git info at bottom right */
    bottom: 20px;
}

.scroll-top-icon:hover {
    opacity: 1 !important;
}

@keyframes fade-in-scroll {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 0.5;
    }
}

.abs-top-right {
    position: absolute;
    top: 10px;
    right: 10px;
}

.pointer {
    cursor: pointer;
}

.tooltip-sub-container {
    width: 150px;
}

.forced-min-height {
    min-height: 300px;
}

.cycle-stat-container {
    margin-left: 45px;
    flex: 1;
    /*max-height: 830px;*/
}

.cycle-stat-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex: 1; /* stretch to the remaining space in flex div */
}

.text-align-end {
    text-align: end;
}

.inline-flex {
    display: inline-flex;
    flex-direction: column;
    width: 96%;
}

.flex-fill {
    flex: 1;
}

.two-by-two-grid {
    display: grid;
    grid-template-columns: 2fr 2fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}

.child-row-indent td:first-child{
    padding-left: 30px;
    border-left: 2px orange solid;
}

.view-critical-filter {
    width: 235px;
    margin-bottom: 10px;
}

.view-critical-filter_container {
    margin-top: 20px;
}

.show-data-filter-btn{
    height: 35px;
    margin-left: 15px !important;
    margin-bottom: 13px !important;
}

.device-health-accordion{
    max-width: max-content !important;
    margin-bottom: 20px
}
