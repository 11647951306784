.cm-main-container{
    height: 100vh;
}

.cm-header-div{
    margin: 10px;
}

.cm-filter-container{
    margin: 25px;
    width: 50%;
}

.cm-date-header {
    margin-bottom: 7px !important;
    margin-left: 7px;
    margin-top: 15px !important;
}

.cm-filter-div{
    margin-bottom: 15px;
}

.cm-datepicker{
    margin-right: 15px !important;
}

.digital-output-checkbox{
    justify-content: space-between;
}

.mui-three-across-grid-container {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 33% 33% 34%;
    row-gap: 6px;
    column-gap: 16px;
  }
