.flex-row{
    display: flex;
    justify-content: space-between;
}
.flex-row-expanded{
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
}

.top-row{
    margin-bottom: 15px;
}

.flex-item{
    flex: 1;
    width: 100%;
}

.dashboard-large-container{
    /*display: flex;*/
    margin-right: 20px;
    margin-left: 20px;
}

.graph-container--large{
    width: 380px;
}

.graph-container--large-x2{
    width: 775px;
}

/*
    Don't use a fixed width here. Let highcharts figure out how wide it should be.
 */
.graph-container--expanded{
    margin-bottom: 20px;
}

.graph-container--medium{
    width: 400px;
    margin-bottom: 5px;
    margin-top: 5px;
}

/*
    Don't use a fixed width here. Mobile screen sizes are too variable, let the graph fit to the screen's dimensions
 */
.graph-container--mobile{
    /*width: 600px;*/
    margin-top: 5px;
    margin-bottom: 5px;
}

.customer-container-mobile{
    /*max-width: none !important;*/
    /*width: 1200px !important;*/
    display: flex;
    flex-direction: column;
    padding-right: 30px;
    padding-left: 30px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
}

.cycle-table{
    background: white;
    /* margin: 16px; */
    /* padding: 100px; */
    width: 775px;
    padding: 10px;
}

.expanded-cycle-table{
    background: white;
    /* margin: 16px; */
    /* padding: 100px; */
    /*width: 775px;*/
    padding: 10px;
}

.full-cycle-table{
    background: white;
    width: 100%;
    padding: 10px;
    margin-top: 5px;
}


.no-cycles-found-dialogue{
    text-align:center;
    margin-top: 50px;
}

.no-cycles-found-dialogue h2 {
    font-weight : 300;
    font-size: 2rem;
}

.no-cycles-found-dialogue h3 {
    font-weight : 300;
    font-size: 1.71428571rem;
}


.activated-btn{
    background-color: green !important;
    color: white !important;
}

.unactivated-btn{
    color: #333 !important;
    background-color: #fff !important;
    /*border-color: #ccc !important;*/
    border: 1px solid #ccc !important;
}

.hidden{
    display: none;
}

.expand-btn{
    width: 50px;
    height: 50px;
    text-align: center;
    cursor: pointer;
}

.orange-text{
    color: darkorange;
    opacity: 1 !important;
}

.cycle-table-header{
    display: flex;
    justify-content: space-between;
}

.btn-div{
    display: flex;
}

.left-btn-no-padding{
    margin-right: 0 !important;
}

.report-btn{
    border-radius: 0 !important;
}

.dashboard-container{
    background-color: white;
}

.biochallenge-info{
    margin-left: 10px;
    display: grid;
}

.biochallenge-info > strong {
    margin-top: 5px;
}

.biochallenge-required-now-dialogue{
    width: 380px;
    height: 250px;
    font-weight: 700;
    color: #e41e1e;
    text-align: center;
    align-items: center;
    display: flex;
    padding: 18px;
    font-size: 1.5em;
    flex-direction: column;
    justify-content: center;
}

.biochallenge-required-now-icon{
    padding-bottom: 20px;
}

.customer-devices-checkbox{
    opacity: .33;
}

.customer-devices-checkbox:hover {
    opacity: 1;
}

.dashboard-nav-right{
    flex-flow: wrap-reverse;
    display: flex;
}


.show-data-btn{
    height: 40px;
    margin-left: 15px !important;
    /*margin-bottom: 14px !important;*/
}