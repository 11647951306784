.activated-summary-btn{
    background-color: green !important;
    color: white !important;
}
.activated-summary-btn:hover{
    background-color: rgb(14, 148, 14) !important;
    color: white !important;
}

.unactivated-summary-btn{
    background-color: #e0e1e2 !important;
    color: rgba(0,0,0,.6) !important;
    /*border-color: #ccc !important;*/
    border: 1px solid #ccc !important;
}

.unactivated-summary-btn:hover{
    background-color: #cacbcd !important;
    color: rgba(0,0,0,.8) !important;
    /*border-color: #ccc !important;*/
    border: 1px solid #ccc !important;
}

.summary-dashboard-btn{
    height: 40px;
}