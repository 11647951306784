.facility-serials{
    max-height: 200px;
    overflow-y: auto;
    margin-top: 10px;
    text-align: initial;
}

.split-container{
    width: 100%;
    display: flex;
}

.fifty-split{
    width: 50%;
}

.minor-left-right-padding{
    padding-left: 5px;
    padding-right: 5px;
}

.wide-search{
    width: 300px;
}

.table-sub-header{
    display: flex;
    flex-direction: column;
}

.ster-content{
    /*height: 70vh !important;*/
    /*overflow-y: auto;*/
}

.customer-stats{
    width: 700px;
    display: flex;
}

.search-div{
    display: flex;
    flex-direction: column;
}

.error-code-dates{
    height: 75px;
}