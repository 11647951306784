@import url('https://fonts.googleapis.com/css?family=Raleway');
@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url('https://fonts.googleapis.com/css?family=Poppins');

.center {
    font-family: 'Raleway', sans-serif;
    background-color: #1abc9c;
    /*position: absolute;*/
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.card {
    background-color: #fff;
    border-radius: 15px;
    padding: 0.8rem;
}

.card>form {
    display: flex;
    flex-direction: column;
}

.card h1 {
    text-align: center;
    margin-top: 0;
    margin-bottom: 10px;
}

.form-item {
    font-family: 'Montserrat', sans-serif;
    padding: 5px;
    margin-bottom: 2rem;
    height: 30px;
    width: 16rem;
    border: 1px solid grey;
}

.form-submit {
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    border: none;
    letter-spacing: 0.2rem;
    transition: 0.3s opacity ease;
    cursor: pointer;
    background-color: #009FC2;
    padding: 12px 14px;
    width: 100%;
    height: 45px;

}
.form-submit:hover {
    opacity: 0.6;
}
.form-submit:disabled {
    cursor: not-allowed;
    background-color: #a4a5a3;
}

.ui.basic.segment {
    flex: 1;
}

.pusher.main.default-background {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.login-header {
    background-color: #009FC2 !important;
    text-align: center;
    padding: 12px 10px !important;
}

.login-header-ster-solutions {
    background-color: #3c8da7;
    text-align: center;
    padding: 12px 10px !important;
}

.login-form {
    background-color: #fff;
    border-radius: 6px;
    margin: 35px auto;
    width: 350px;
    height: 425px;
}

.login-body {
    padding: 35px 50px;
}

.login-intro {
    text-align: center;
    font-size: 14px;
    color: #aaa;
    margin-bottom: 35px;
}

.top-border-radius {
    border-radius: 15px 15px 0px 0px;
}

.login-error {
    text-align: center;
    font-size: 16px;
    color: #cb3333;
    margin-top: 10px;
    margin-bottom: 10px;
}

.full-size-loader {
    height: 99vh !important;
}

.light-hyperlink {
    text-align: center;
    font-size: 15px;
    padding-top: 5px;
}

.short-failed-login-form {
    height: 500px;
}

.failed-login-form {
    height: 570px;
}

.light-validation-error {
    color: rgba(255, 5, 2, 0.51);
}

.highcharts-background,
.highcharts-root,
.highcharts-container {
    max-height: 100%;
    max-width: 100%;
}
